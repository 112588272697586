
// export const API_URL = 'http://localhost:5000';
//  export const API_URL = 'https://cors.goplugin.co/http://ec2-18-220-249-62.us-east-2.compute.amazonaws.com:5000';
export const API_URL = 'https://oracles.goplugin.co';
export const API_URL_V2 = 'https://validator.goplugin.co';
export const FEEDS_API_URL = 'https://feedsapi.goplugin.co/';

export const MINIMUM_DEPOSIT = 1;
export const MAXIMUM_DEPOSIT = 100;

//Mainnet
export const PLI_CONTRACT_ADDRESS = "0xff7412ea7c8445c46a8254dfb557ac1e48094391";
export const RPC_URL = "https://xdcfnode1.goplugin.co";
export const EXPLORER_LINK = "https://explorer.xinfin.network";

// //APOTHEM
// export const PLI_CONTRACT_ADDRESS = "0xb3db178db835b4dfcb4149b2161644058393267d";
// export const RPC_URL = "https://apothemxdcpayrpc.blocksscan.io";
// export const EXPLORER_LINK = "https://explorer.apothem.network";


export const RPC_CONFIG = {

    "XDC Mainnet": {
        PLI_CONTRACT_ADDRESS: "0xff7412ea7c8445c46a8254dfb557ac1e48094391",
        RPC_URL: "https://rpc.ankr.com/xdc",
        EXPLORER_LINK: "https://explorer.xinfin.network",
        NETWORK: 50
    },
    "XDC Apothem": {
        PLI_CONTRACT_ADDRESS: "0x33f4212b027E22aF7e6BA21Fc572843C0D701CD1",
        RPC_URL: "https://xdcapothemfnode1.plugin.global",
        EXPLORER_LINK: "https://explorer.apothem.network",
        NETWORK: 51,

    },
    "Polygon Mainnet": {
        PLI_CONTRACT_ADDRESS: "0x7540F227daEB674FC1a81D362401A90E2228234d",
        RPC_URL: "https://polygon-mainnet.g.alchemy.com/v2/DnntwB943CiuGcecxjpTOLfFGm2Z13Uf",
        EXPLORER_LINK: "https://polygonscan.com",
        NETWORK: 137,

    },
    "Polygon Mumbai": {
        PLI_CONTRACT_ADDRESS: "0x26FD686728D7bFEfD575592e1ef75E607EB1A209",
        RPC_URL: "https://polygon-mumbai.g.alchemy.com/v2/zG7YTFY-C4PqNM7tqTNksJ-4Ab3p_kJc",
        EXPLORER_LINK: "https://mumbai.polygonscan.com",
        NETWORK: 80001,

    },
    "Polygon Amoy": {
        PLI_CONTRACT_ADDRESS: "0x7540F227daEB674FC1a81D362401A90E2228234d",
        RPC_URL: "https://80002.rpc.thirdweb.com",
        EXPLORER_LINK: "https://www.oklink.com/amoy",
        NETWORK: 80002,

    },
}

export const ETH_ADDRESS_REGEX = /^(0x)?[0-9a-fA-F]{40}$/;


export const checkValidAddress = (address) => {
    const isValidEthereumAddress = ETH_ADDRESS_REGEX.test(address);
    return isValidEthereumAddress
}

export const decimalToHex = (decimalNumber, expectedLength = 64) => {
    // Convert to hexadecimal and pad with zeros
    const hexValue = Math.abs(decimalNumber).toString(16).toUpperCase();
    console.log("hexValue::::", hexValue);
    return "0x" + "0".repeat(expectedLength - hexValue.length) + hexValue;
}


export const SUPPORT_IMAGE = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 118">
    <path
        d="M8.916,94.745C-.318,79.153-2.164,58.569,2.382,40.578,7.155,21.69,19.045,9.451,35.162,4.32,46.609.676,58.716.331,70.456,1.845,84.683,3.68,99.57,8.694,108.892,21.408c10.03,13.679,12.071,34.71,10.747,52.054-1.173,15.359-7.441,27.489-19.231,34.494-10.689,6.351-22.92,8.733-34.715,10.331-16.181,2.192-34.195-.336-47.6-12.281A47.243,47.243,0,0,1,8.916,94.745Z"
        transform="translate(0 -1)"
        fill="#f6faff"
    />
    <rect
        x={18}
        y={32}
        width={84}
        height={50}
        rx={4}
        ry={4}
        fill="#fff"
    />
    <rect
        x={26}
        y={44}
        width={20}
        height={12}
        rx={1}
        ry={1}
        fill="#ff620c7a"
    />
    <rect
        x={50}
        y={44}
        width={20}
        height={12}
        rx={1}
        ry={1}
        fill="#ff620c7a"
    />
    <rect
        x={74}
        y={44}
        width={20}
        height={12}
        rx={1}
        ry={1}
        fill="#ff620c7a"
    />
    <rect
        x={38}
        y={60}
        width={20}
        height={12}
        rx={1}
        ry={1}
        fill="#ff620c7a"
    />
    <rect
        x={62}
        y={60}
        width={20}
        height={12}
        rx={1}
        ry={1}
        fill="#ff620c7a"
    />
    <path
        d="M98,32H22a5.006,5.006,0,0,0-5,5V79a5.006,5.006,0,0,0,5,5H52v8H45a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H73a2,2,0,0,0,2-2V94a2,2,0,0,0-2-2H66V84H98a5.006,5.006,0,0,0,5-5V37A5.006,5.006,0,0,0,98,32ZM73,94v4H45V94Zm-9-2H54V84H64Zm37-13a3,3,0,0,1-3,3H22a3,3,0,0,1-3-3V37a3,3,0,0,1,3-3H98a3,3,0,0,1,3,3Z"
        transform="translate(0 -1)"
        fill="#ff620c"
    />
    <path
        d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
        transform="translate(0 -1)"
        fill="#ff620c"
    />
    <path
        d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
        transform="translate(0 -1)"
        fill="none"
        stroke="#ff620c"
        strokeMiterlimit={10}
        strokeWidth={2}
    />
    <line
        x1={40}
        y1={22}
        x2={57}
        y2={22}
        fill="none"
        stroke="#fffffe"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
    />
    <line
        x1={40}
        y1={27}
        x2={57}
        y2={27}
        fill="none"
        stroke="#fffffe"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
    />
    <line
        x1={40}
        y1={32}
        x2={50}
        y2={32}
        fill="none"
        stroke="#fffffe"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
    />
    <line
        x1="30.5"
        y1="87.5"
        x2="30.5"
        y2="91.5"
        fill="none"
        stroke="#ff620c"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
    <line
        x1="28.5"
        y1="89.5"
        x2="32.5"
        y2="89.5"
        fill="none"
        stroke="#ff620c"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
    <line
        x1="79.5"
        y1="22.5"
        x2="79.5"
        y2="26.5"
        fill="none"
        stroke="#ff620c"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
    <line
        x1="77.5"
        y1="24.5"
        x2="81.5"
        y2="24.5"
        fill="none"
        stroke="#ff620c"
        strokeLinecap="round"
        strokeLinejoin="round"
    />
    <circle
        cx="90.5"
        cy="97.5"
        r={3}
        fill="none"
        stroke="#ff620c"
        strokeMiterlimit={10}
    />
    <circle
        cx={24}
        cy={23}
        r="2.5"
        fill="none"
        stroke="#ff620c"
        strokeMiterlimit={10}
    />
</svg>

